import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"

const PageNotFound = () => {
  return (
    <Layout>
      <div className="px-12 w-screen text-center">
        <h1 className="mt-20 mb-8">Oops! You hit a dead end!</h1>
        <Link to="/">Go Home</Link>
      </div>
    </Layout>
  )
}

export default PageNotFound
